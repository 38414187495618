var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-news"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("大科城软件产业联盟拍了拍你，新云网智慧亮剑！")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"a-more"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails2')}}},[_vm._v("上一条：大科城软件产业联盟拍了拍你，新云网智慧亮剑！")]),_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails4')}}},[_vm._v("下一条：用强有力的奖励政策 加速科技成果转化落地")])]),_c('el-button',{attrs:{"plain":""},on:{"click":function($event){return _vm.backGo()}}},[_vm._v(" 返回 ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-setting"},[_c('h3',{staticClass:"time"},[_vm._v(" 发布时间： 2020-08-03 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"52px"}},[_c('p',{staticStyle:{"text-align":"justify","text-indent":"36px"}},[_vm._v(" 3月28日，岳麓山大学科技城软件产业生态圈共建计划启动，岳麓山大学科技城软件和信息技术服务业产业联盟同步成立。省委常委、长沙市委书记胡衡华出席见证并致辞。他表示：“这次大科城软件产业生态圈共建计划的启动，是长沙‘软件业再出发’的一个良好开端。” ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"10px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news3/new1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 省委常委、市委书记胡衡华致辞 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"10px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news3/new2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 大科城软件和信息技术服务业产业联盟成立 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify","text-indent":"36px"}},[_vm._v(" 7月17日，岳麓山大学科技城软件和信息技术服务业产业联盟第一次工作会议在岳麓科创港顺利召开。岳麓区副区长李舜、岳麓山大学科技城管委会副主任周建华等领导，中南大学、湖南大学、湖南师范大学等5所高校计算机学院相关负责人以及25家企业负责人参加会议。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"30px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news3/new3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 大科城软件和信息技术服务业联盟成员合影 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify","text-indent":"36px"}},[_vm._v(" 此次会议投票通过了联盟章程，同时，选举出中南大学等5所高校，腾讯云计算公司、京东云计算公司等5家企业为第一届理事长单位，湖南新云网科技有限公司为理事长单位，新诤信知识产权运营有限公司当选联盟副理事长单位，湖南岳麓山大学科技园创业服务中心为联盟秘书长单位，湖南大科城知识产 权服务有限公司当选副秘书长单位，中南大学计算机学院副院长曾锋等15人为联盟专家委员会成员，并颁发了牌匾和聘书。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"30px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news3/new4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 中南大学计算机学院当选为理事长单位、新诤信知识产权运营有限公司当选为副理事长单位、湖南大科城知识产权服务有限公司当选为副秘书长单位。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify","text-indent":"36px"}},[_vm._v(" 此次会议投票通过了联盟章程，同时，选举出中南大学等5所高校，腾讯云计算公司、京东云计算公司等5家企业为第一届理事长单位，湖南新云网科技有限公司为理事长单位，新诤信知识产权运营有限公司当选联盟副理事长单位，湖南岳麓山大学科技园创业服务中心为联盟秘书长单位，湖南大科城知识产 权服务有限公司当选副秘书长单位，中南大学计算机学院副院长曾锋等15人为联盟专家委员会成员，并颁发了牌匾和聘书。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" ~ 联盟沙龙 · 名企探访 ~ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify","text-indent":"36px"}},[_vm._v(" 7月31日，大科城软件和信息技术服务业产业联盟在理事长单位——湖南新云网科技有限公司举行第一次沙龙活动。岳麓区工信局局长谢俊，联盟企业腾讯云、湖南航天、新诤信、大科城知识产权等11家企业20余人参加此次活动。与会人员在新云网李政军总裁的介绍下参观并考察了新云网透明计算基 础设施和支撑平台，详细了解智慧医疗在自助取片诊断、心电监测、健康检测等领域创新成果，现场体验了智慧教育应用场景。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"10px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news3/new5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 调研新云网透明计算技术及其应用 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"10px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news3/new6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 体验新云网智慧医疗产品 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"10px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news3/new7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 体验新云网智慧教育产品 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"10px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news3/new8.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 各位企业代表进行沙龙座谈 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify","text-indent":"36px"}},[_vm._v(" 各联盟会员单位就企业产品、人才培养、企业合作进行了座谈。新云网李政军总裁介绍了公司发展的现状及未来规划，希望各联盟单位能为公司发展献计献策。湖南师大蒋少华教授、联盟企业湖南安恒、湖南大电能源，都进行了踊跃发言。新诤信第二总部总经理欧智祯从知识产权、品牌活动等方面提供了建议。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify","text-indent":"36px"}},[_vm._v(" 新云网，作为联盟理事长单位，既是国家高新科技成果的转化者，原创科技的自主研发者，也是网络系统集成方案及产品的提供者，更是互联物联系列终端产品的智造者。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"10px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news3/new9.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin":"20px 0"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 联盟企业探访新云网 ")])])
}]

export { render, staticRenderFns }