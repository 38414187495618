<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2020-12-15 14:08:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="preview-news">
    <div class="main">
      <div class="left">
        <h3>大科城软件产业联盟拍了拍你，新云网智慧亮剑！</h3>
        <div class="news-setting">
          <h3 class="time">
            发布时间： 2020-08-03
          </h3>
          <!-- <h5 class="num">
            浏览量： 28289
          </h5> -->
        </div>
        <div class="news-content" style="margin-top:52px">
          <p style="text-align:justify;text-indent: 36px;">
            3月28日，岳麓山大学科技城软件产业生态圈共建计划启动，岳麓山大学科技城软件和信息技术服务业产业联盟同步成立。省委常委、长沙市委书记胡衡华出席见证并致辞。他表示：“这次大科城软件产业生态圈共建计划的启动，是长沙‘软件业再出发’的一个良好开端。”
          </p>
        </div>
        <div class="img-box" style="margin-top:10px">
          <img src="../../assets/images/news/news3/new1.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p class="img-explain">
            省委常委、市委书记胡衡华致辞
          </p>
        </div>
        <div class="img-box" style="margin-top:10px">
          <img src="../../assets/images/news/news3/new2.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p class="img-explain">
            大科城软件和信息技术服务业产业联盟成立
          </p>
        </div>
        <div class="news-content" style="margin-top:20px">
          <p style="text-align:justify;text-indent: 36px;">
            7月17日，岳麓山大学科技城软件和信息技术服务业产业联盟第一次工作会议在岳麓科创港顺利召开。岳麓区副区长李舜、岳麓山大学科技城管委会副主任周建华等领导，中南大学、湖南大学、湖南师范大学等5所高校计算机学院相关负责人以及25家企业负责人参加会议。
          </p>
        </div>
        <div class="img-box" style="margin-top:30px">
          <img src="../../assets/images/news/news3/new3.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p class="img-explain">
            大科城软件和信息技术服务业联盟成员合影
          </p>
        </div>
        <div class="news-content" style="margin-top:20px">
          <p style="text-align:justify;text-indent: 36px;">
            此次会议投票通过了联盟章程，同时，选举出中南大学等5所高校，腾讯云计算公司、京东云计算公司等5家企业为第一届理事长单位，湖南新云网科技有限公司为理事长单位，新诤信知识产权运营有限公司当选联盟副理事长单位，湖南岳麓山大学科技园创业服务中心为联盟秘书长单位，湖南大科城知识产
            权服务有限公司当选副秘书长单位，中南大学计算机学院副院长曾锋等15人为联盟专家委员会成员，并颁发了牌匾和聘书。
          </p>
        </div>
        <div class="img-box" style="margin-top:30px">
          <img src="../../assets/images/news/news3/new4.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p class="img-explain">
            中南大学计算机学院当选为理事长单位、新诤信知识产权运营有限公司当选为副理事长单位、湖南大科城知识产权服务有限公司当选为副秘书长单位。
          </p>
        </div>
        <div class="news-content" style="margin-top:20px">
          <p style="text-align:justify;text-indent: 36px;">
            此次会议投票通过了联盟章程，同时，选举出中南大学等5所高校，腾讯云计算公司、京东云计算公司等5家企业为第一届理事长单位，湖南新云网科技有限公司为理事长单位，新诤信知识产权运营有限公司当选联盟副理事长单位，湖南岳麓山大学科技园创业服务中心为联盟秘书长单位，湖南大科城知识产
            权服务有限公司当选副秘书长单位，中南大学计算机学院副院长曾锋等15人为联盟专家委员会成员，并颁发了牌匾和聘书。
          </p>
        </div>
        <div class="news-content" style="margin-top:20px">
          <p class="img-explain">
            ~ 联盟沙龙 · 名企探访 ~
          </p>
        </div>
        <div class="news-content" style="margin-top:20px">
          <p style="text-align:justify;text-indent: 36px;">
            7月31日，大科城软件和信息技术服务业产业联盟在理事长单位——湖南新云网科技有限公司举行第一次沙龙活动。岳麓区工信局局长谢俊，联盟企业腾讯云、湖南航天、新诤信、大科城知识产权等11家企业20余人参加此次活动。与会人员在新云网李政军总裁的介绍下参观并考察了新云网透明计算基
            础设施和支撑平台，详细了解智慧医疗在自助取片诊断、心电监测、健康检测等领域创新成果，现场体验了智慧教育应用场景。
          </p>
        </div>

        <div class=" img-box" style="margin-top:10px">
          <img src="../../assets/images/news/news3/new5.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p class="img-explain">
            调研新云网透明计算技术及其应用
          </p>
        </div>

        <div class="img-box" style="margin-top:10px">
          <img src="../../assets/images/news/news3/new6.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p class="img-explain">
            体验新云网智慧医疗产品
          </p>
        </div>
        <div class="img-box" style="margin-top:10px">
          <img src="../../assets/images/news/news3/new7.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p class="img-explain">
            体验新云网智慧教育产品
          </p>
        </div>

        <div class="img-box" style="margin-top:10px">
          <img src="../../assets/images/news/news3/new8.png" alt="">
        </div>
        <div class="news-content" style="margin-top:20px">
          <p class="img-explain">
            各位企业代表进行沙龙座谈
          </p>
        </div>

        <div class="news-content" style="margin-top:20px">
          <p style="text-align:justify;text-indent: 36px;">
            各联盟会员单位就企业产品、人才培养、企业合作进行了座谈。新云网李政军总裁介绍了公司发展的现状及未来规划，希望各联盟单位能为公司发展献计献策。湖南师大蒋少华教授、联盟企业湖南安恒、湖南大电能源，都进行了踊跃发言。新诤信第二总部总经理欧智祯从知识产权、品牌活动等方面提供了建议。
          </p>
        </div>
        <div class="news-content" style="margin-top:20px">
          <p style="text-align:justify;text-indent: 36px;">
            新云网，作为联盟理事长单位，既是国家高新科技成果的转化者，原创科技的自主研发者，也是网络系统集成方案及产品的提供者，更是互联物联系列终端产品的智造者。
          </p>
        </div>
        <div class="img-box" style="margin-top:10px">
          <img src="../../assets/images/news/news3/new9.png" alt="">
        </div>
        <div class="news-content" style="margin:20px 0;">
          <p class="img-explain">
            联盟企业探访新云网
          </p>
        </div>

        <div class="pagination">
          <div class="a-more">
            <span @click="$router.push('/indexOs/newsDetails2')">上一条：大科城软件产业联盟拍了拍你，新云网智慧亮剑！</span>
            <span @click="$router.push('/indexOs/newsDetails4')">下一条：用强有力的奖励政策	加速科技成果转化落地</span>
          </div>
          <el-button plain @click="backGo()">
            返回
          </el-button>
        </div>
      </div>
      <!-- 搜索区域 -->
      <!-- <div class="search-right">
        <el-input
          v-model="keyWord"
          placeholder="查询你喜欢的内容"
          suffix-icon="el-icon-search"
        />
        <div class="hot-news">
          <div class="hot-title">
            <span>最热文章</span>
            <i class="hot-icon" />
          </div>
          <div class="lb-news">
            <router-link class="dg-news" to="/indexOs/newsDetails1">
              <img src="../../assets/images/news/news2-1.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  中共中央政治局常委、国务院副总理 韩正来我公司视察
                </h3>
                <h5 class="time">
                 2020-06-01
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails2">
              <img src="../../assets/images/news/news2-2.png">
              <div class="news-introduce">
                <h3 class="news-title">
                    岳麓山大学科技城：强化科技人才支撑 打造湖南创新...
                </h3>
                <h5 class="time">
                  2020-06-03
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails3">
              <img src="../../assets/images/news/news2-3.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  大科城软件产业联盟拍了拍
                </h3>
                <h5 class="time">
                  2020-08-03
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewNews',
  data() {
    return {
      keyWord: ''
    };
  },
  methods: {
    backGo() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #F5F5F5;
    .main{
      width: 800px;
      height: auto;
      margin: 0 auto;
      display: flex;
      padding-top: 58px;
      justify-content: space-between;
      .left{
        flex: 1;
        margin-right: 63px;
        h3{
          color: #313131;
          font-size:24px;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:18px;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          p{
            font-size: 18px;
            color: #313131;
            line-height: 32px;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:16px;
          }
        }
        .img-box{
          width: 100%;
      display: flex;
      justify-content: center;
          img{
            width:800px;
          }
        }
      }
      .pagination{
        display: flex;
        justify-content: space-between;
        padding: 50px 0 150px;
        border-top: 1px solid #DDDDDD;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:18px;
          line-height:32px;
          color:rgba(49,49,49,1);
          span{
            display: inline-block;
            width:600px;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            cursor: pointer;
          }
        }
        .el-button{
          width: 134px;
          height: 48px;
          border: 1px solid rgba(253,156,2,1);
          opacity: 1;
          border-radius: 10px;
          font-size:24px;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      .search-right{
        width: 334px;
        .el-input{
          width: 329px;
          height: 45px;
        }
        .el-input__inner{
          border-radius: 5px;
        }
        .el-input__icon{
          font-size: 24px;
        }
        .hot-news{
          .hot-title{
            font-size:24px;
            margin-bottom: 12px;
            color:#313131;
            .hot-icon{
              display: inline-block;
              background: url('../../assets/images/news/hot.png');
              width: 18px;
              height: 22px;
              margin-left: 6px;
            }
          }
          .dg-news{
            display: flex;
            margin-bottom: 22px;
            img{
              width:132px;
              height:76px;
            }
            .news-introduce{
              flex: 1;
              margin-left: 8px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .news-title{
                font-size:16px;
                font-weight: 600;
                color: #313131;
                box-sizing: border-box;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;
                overflow: hidden;
              }
              .time{
                font-size:18px;
                color: #B5B5B5;
                font-weight: 100;
              }
            }
            &:hover{
              .news-title{
                color:rgba(253,156,2,1);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #fff;
    .main{
      width: 100%;
      height: auto;
      display: flex;
      padding-top: 1rem;
      justify-content: space-between;
      padding:1.07rem .48rem;
      .left{
        flex: 1;
        h3{
          color: #313131;
          font-size:.48rem;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:.267rem;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          width: 100%;
          p{
            font-size: .373rem;
            color: #313131;
            line-height: .7rem;
            text-indent: .747rem;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:.267rem;
          }
        }
        .img-box{
          width: 100%;
          display: flex;
          justify-content: center;
          img{
            width:100%;
            height: 100%;
          }
        }
      }
      .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: .53rem;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:.32rem;
          span{
            display: inline-block;
            width:8.933rem;
            cursor: pointer;
            color:rgba(49,49,49,1);
            height: auto;
            padding:.267rem .32rem;
            border: 1px solid #8A8684;
            border-radius: .267rem;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            margin-bottom: .48rem;
          }
        }
        .el-button{
          width: 100%;
          font-size:.427rem;
          border: 1px solid rgba(253,156,2,1);
          padding:.267rem .32rem;
          border-radius: .267rem;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      // .search-right{
      //   width: 334px;
      //   .el-input{
      //     width: 329px;
      //     height: 45px;
      //   }
      //   .el-input__inner{
      //     border-radius: 5px;
      //   }
      //   .el-input__icon{
      //     font-size: 24px;
      //   }
      //   .hot-news{
      //     .hot-title{
      //       font-size:24px;
      //       margin-bottom: 12px;
      //       color:#313131;
      //       .hot-icon{
      //         display: inline-block;
      //         background: url('../../assets/images/news/hot.png');
      //         width: 18px;
      //         height: 22px;
      //         margin-left: 6px;
      //       }
      //     }
      //     .dg-news{
      //       display: flex;
      //       margin-bottom: 22px;
      //       img{
      //         width:132px;
      //         height:76px;
      //       }
      //       .news-introduce{
      //         flex: 1;
      //         margin-left: 8px;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: space-between;

      //         .news-title{
      //           font-size:16px;
      //           font-weight: 600;
      //           color: #313131;
      //           box-sizing: border-box;
      //           text-overflow:ellipsis;
      //           display:-webkit-box;
      //           -webkit-box-orient:vertical;
      //           -webkit-line-clamp:2;
      //           overflow: hidden;
      //         }
      //         .time{
      //           font-size:18px;
      //           color: #B5B5B5;
      //           font-weight: 100;
      //         }
      //       }
      //       &:hover{
      //         .news-title{
      //           color:rgba(253,156,2,1);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

</style>
